import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import { GetURL } from "../Common/site/functions";
import SearchOverlay from "../SearchOverlay/SearchOverlay"
import ModalTiny from "react-bootstrap/Modal"
import BookViewingForm from "../Forms/book-a-viewing-form"; 
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
// Images
import BrandLogo from "../../images/logo.svg"
import _ from "lodash"

// import '../Header/Header.scss';
const PropertyFooter = (props) => {  
  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const [propertyImg, setPropertyImg] = useState(process.env.GATSBY_SITE_URL + '/images/property-image.jpg');
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  const [Shareicons, setShareicons] = React.useState(false);

  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  const trackerShare = (event) => {
    var shareURL = typeof window !== 'undefined' ? window.location.href : ''
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareURL,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  useEffect(() => { 
    if (props.data.images && props.data.images.length != 0) {
      setPropertyImg(props.data.images[0].srcUrl);
    }
  }, []);

  return (
    <>
      <div className={`property-footer d-lg-none`} >
        <Container>
          <Row>
            <Col lg={12}>
              <div className="feature-section"> 
                <div className="footer-section-btn">
                <a href="javascript:void(0)" className="btn btn-primary d-md-flex" onClick={() => { openValuationformModal() }}>Arrange a viewing <i className="icon-arrow"></i></a>
                <div >
                    <a href="javascript:void(0)"  onClick={()=>{setShareicons(!Shareicons)}}  className="btn-circle"> <i className="icon-share"></i>
                      {Shareicons &&
                        <div className="property-share-social-icons">
                          <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                          </FacebookShareButton>
                          <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                          </TwitterShareButton>
                          <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                          </LinkedinShareButton>
                          <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                          </WhatsappShareButton>
                        </div>
                      }
                    </a>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalTiny size="lg" show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Arrange a Viewing</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <BookViewingForm
            title={`Arrange a Viewing`}
            type="Sales"
            property_img={propertyImg}
            property_id={props.data && props.data.id ? props.data.id : ''}
            page_url={window.location.href}
            property_title={props.data && props.data.display_address ? props.data.display_address : ''}
            property_display_address={props.data && props.data.display_address ? props.data.display_address : ''}
          />
        </ModalTiny.Body>
      </ModalTiny>
    </>
  )
}



export default PropertyFooter