import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
// import Logo from '../images/icon.png';
import _ from 'lodash';
import Header from "../components/Header/Header";
import PropertyHeader from "../components/Header/PropertyHeader";
import Footer from "../components/Footer/Footer";
import PropertyFooter from "../components/Footer/PropertyFooter";
import ProductBanner from '../components/ProductDetails/ProductBanner/ProductBanner'
import FeatureDescription from '../components/ProductDetails/FeatureDescription/FeatureDescription'
import SimilarProperties from '../components/GuideDetails/FeaturedProperties/SimilarProperties'
// import AreaGuid from '../components/ProductDetails/AreaGuid/AreaGuid'
// import $ from 'jquery';
import SEO from "../components/seo";
import logoBlack from "../images/logoblack.svg";
// Myaccount
import { UserObjectStoreProvider } from "@myaccount/website-service";

import { inViewOptions, containerVariants, fadeInFromRight, fadeInFromBottom } from '../components/Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id}) {
      id
      title
      display_address 
      search_type
      price 
      images
      floorplan 
      imagetransforms
      bedroom
      bathroom
      reception
      negotiator_email
      description
      long_description
      room_details
      latitude
      longitude
      address
      brochure
      crm_negotiator_id
      accomadation_summary
      department
      price_qualifier
      virtual_tour
      status
      extra
    }
  }
`;


function PropertyDetailsTemplate(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});

  const [scroll, setScroll] = useState(false);
  const [upScroll, setUpScroll] = useState(false);
  const [propHeader, setPropHeader] = useState(false);

  const handlerClick = () => {
    setShowMenu(!showMenu)
    var root = document.getElementsByTagName('html')[0];
    if (showMenu == true) {
      document.body.className = '';
      root.className = '';
    } else {
      document.body.classList.add("overflow-hidden");
      root.classList.add("overflow-hidden");
    }
  }
  const [showSearch, setShowSearch] = useState(false);
  const handlerSearchClick = () => {
    setShowSearch(!showSearch)
    setShowMenu(false)
    if (showSearch == true) {
      document.body.className = '';
    } else {
      document.body.classList.add("overflow-hidden");
    }
  }



  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");



  // similar properties
  const [propItems, setPropItems] = useState([])
  const [render, setRender] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      if (data && data.length > 0) {
        setPropItems(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  // similar properties

  useEffect(() => {
    // similar properties
    // $('head').append('<meta name="twitter:card" content="summary_large_image" />');
    // $('head').append('<meta name="twitter:title" content="Estate Agents in Shropshire, Staffordshire and West Midlands" />');
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let property_slug = pageurl.split('-');
    let similar_property_id = property_slug[property_slug.length - 1];
    let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Similar-properties?pid=" + similar_property_id.replace("/#", "").replace("/", "").split("?")[0];
    getitems(url)
    // similar properties
    var lastScrollTop = 0;
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop) {
        // downscroll code
        setUpScroll(false)
      } else {
        // upscroll code
        setUpScroll(true)
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    })

    if(!render){
      setRender(true);
    }


  }, []);
  // const [purl , setUrl] = useState(pageurl)
  // const { loading, error, data:property_details } = useQuery(PROPERTY_FOR_SALE, {
  // variables: { url: id }});

  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }
  });



  if (!loading) {
    if (_.isEmpty(propertyDetails)) {

      setPropertyDetails(property_details.properties[0])
    }
  }


  return (
    <>
      {/* <SEO title={`metaTitle`} description={`metaDesc`} image={` https://dbroberts.q.starberry.com/images/mail-logo.png`} /> */}
      {loading &&
        <div className="static-details gql-loading-screen">
          <Container className={`align-center`}>
            {/* <img src={Logo} alt="DB Roberts" width="100" />
            <div className="gql-loading">Loading ...</div> */}

            <section className={"loader-wrapper1"}>
              <div id="loader-wrapper1">
                <div id="loader1" class="new-loader1">
                  {/* <div className="new-loader-anime1"></div> */}
                  <img className="logo-white" src={logoBlack} className="loader-logo1" alt="DB Roberts logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
              </div>
            </section>
          </Container>
        </div>
      }
      {!loading && render &&
        <div className={(showMenu || showSearch) ? "wrapper header-style open-search-block property-detail" : "wraapper header-style property-detail"}>

          <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={false} />
          {property_details && property_details.properties && property_details.properties.length > 0 ?
            property_details.properties.map((data, i) => {
              const metaTitle = data.title + ' in ' + data.display_address

              const metaDesc = 'Book a viewing for this ' + data.title + ' in ' + data.display_address + ' at ' + data.price + ' with DB Roberts. Contact us for more information about this property.'
              return (
                <>
                  <SEO title={metaTitle} description={metaDesc} image={data.images[0].srcUrl} location={props.location} />
                  <InView as="div" rootMargin="50px 0px" onChange={(inView, entry) => {
                    setPropHeader(!inView)
                  }}>
                    <ProductBanner data={data} />
                  </InView>
                  {propHeader &&
                    <PropertyHeader upScroll={upScroll} data={data} />
                  }
                  <FeatureDescription data={data} />
                  {
                    propItems.length > 0 &&
                    <SimilarProperties place={data.display_address} data={propItems} tag={data.department} />
                  }
                </>
              )
            }) : ''}

          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <motion.div
                className="get-property overflow-hidden"
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={containerVariants}
              >
                <Container>
                  <Row>
                    <Col xl={12}>
                      <div className="get-property-block">
                        <motion.h2 variants={fadeInFromRight}>Let’s start your property journey together.</motion.h2>
                        <motion.div className="btn-row" variants={fadeInFromBottom}>
                          <Link to={`/property-valuation/home-visit-valuation`} className="btn btn-primary">Book a Valuation <i className="icon-arrow"></i></Link>
                          <Link to={`/property/for-sale`} className="btn btn-primary">Find a Property <i className="icon-arrow"></i></Link>
                        </motion.div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </motion.div>
            )}
          </InView>

          {!showSearch && !showMenu && property_details && property_details.properties && property_details.properties.length > 0 ?
            property_details.properties.map((data, i) => {
              return (
                <>
                  <PropertyFooter data={data} />
                </>
              )
            }) : ''}
          <Footer popularSearchType='propertydetails' propertydetails={propertyDetails} />
        </div>
      }
    </>
  )
}

const PropertyDetailsTemplateComp = (props) => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default PropertyDetailsTemplateComp
