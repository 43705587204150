import React, { useState, useEffect } from "react"
import { Container, Row, Col } from 'react-bootstrap';
// import { isMobile, isMobileTab } from '../../jsutility/index'
// import axios from 'axios';
import Slider from "react-slick";
// import MobImg from "../../../images/guide/mob-img01.jpg"
// import TabImg01 from "../../../images/guide/tab-img01.jpg"
// import TabImg02 from "../../../images/guide/tab-img02.jpg"
// import DeskImg01 from "../../../images/guide/desk-img01.jpg"
// import DeskImg02 from "../../../images/guide/desk-img02.jpg"
// import DeskImg03 from "../../../images/guide/desk-img03.jpg"
import FsLightbox from 'fslightbox-react';

import PropertyList from "./PropertyList"
// Style
import './FeaturedProperties.scss';
// import { truncate } from "lodash";

import { inViewOptions, containerVariants, delayItemsFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const SimilarProperties = (props) => {
    const [toggler, setToggler] = useState(false);
    const [lightBoxImages, setLightBoxImages] = useState([]);
    const [lightBoxIndex, setLightBoxIndex] = useState('s0');
    let sale_infinite = false;
    if (props.data.length >= 3) {
        sale_infinite = true
    }
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: sale_infinite,
        arrows: false,
        mobileFirst: true,
        autoplay: false,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    }


    useEffect(() => {
        props.data.map((item, i) => {
            if (item.images) {
                var list = []
                item.images.map((img) => {
                    list.push(img.srcUrl)
                })
                lightBoxImages['sale_image_' + i] = (list)
            }

            if (item.floorplan) {
                var list = []
                item.floorplan.map((img) => {
                    list.push(img.srcUrl)
                })
                lightBoxImages['sale_floor_' + i] = (list)
            }
        })
        setLightBoxImages(lightBoxImages)



    }, [])

    const openImageLightBox = (i) => {
        setLightBoxIndex(i)
        setTimeout(() => {
            setToggler(!toggler)
        }, 50);
    }
    return (
        <React.Fragment>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.section
                        className="feautued-properties similar properties"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                <Col>
                                    <div className="properties">
                                        <span className="sm-text"> SIMILAR PROPERTIES</span>
                                        <p>Is this property not quite right? Here are a few others that may interest you...</p>
                                        <Slider {...settings}>
                                            {props.data.map((item, index) => {
                                                return (
                                                    <motion.div
                                                        className="tab-content"
                                                        key={index}
                                                        custom={index}
                                                        variants={delayItemsFromBottom}
                                                    >
                                                        <PropertyList {...item} lightBox={openImageLightBox} type="sale" index={index} />
                                                    </motion.div>
                                                )
                                            }
                                            )}
                                        </Slider>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.section>
                )}
            </InView>
            <FsLightbox
                toggler={toggler}
                sources={lightBoxImages[lightBoxIndex]}
                type='image'
                key={lightBoxIndex}
            />
        </React.Fragment>
    )
}
export default SimilarProperties